@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap'); */
@font-face {
  font-family: thicccboi;
  src: url(./assets//font/Webfont/THICCCBOI-ExtraBold.woff2) format('woff2');
}
@font-face {
  font-family: thicccboi-medium;
  src: url(./assets//font/Webfont/THICCCBOI-SemiBold.woff2) format('woff2');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
      @apply font-Merriweather;
    }
  }